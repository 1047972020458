@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@200&display=swap');

.App {
  text-align: center;
  overflow-x: hidden;
}

.hide {
  color: red;
}

.trash-bin {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  scale: .7;
  z-index: 10;
  opacity: .7;
  transition: .12s ease-in-out;
}

.explanation-text {
  position: absolute;
  bottom: .5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: .8rem;
  color: #302725;
}

input {
  width: 90%;
  background-color: aliceblue;
  border-radius: 10px;
  outline: none;
}

@keyframes list-entry-in {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes list-entry-out {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(-20%);
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
